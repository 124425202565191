body {
  margin: 0px;
}

.background {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  transition: opacity 2.4s cubic-bezier(0.075, 0.82, 0.165, 1),
    transform 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-size: cover;
  background-position: center;
  background-blend-mode: soft-light;
}

.background-enter {
  opacity: 0.01;
  transform: scale(1.2);
}
.background-enter-active {
  opacity: 1;
  transform: none;
}
.background-exit {
  opacity: 1;
  transform: none;
}
.background-exit-active {
  opacity: 0.01;
  transform: scale(1.2);
}

img.full {
  width: 100%;
}

img.half {
  width: 50%;
}

img.quarter3 {
  width: 80%;
}

img.quarter {
  width: 25%;
}

@media (max-width: 960px) {
  img.half,
  img.quarter {
    width: 80%;
  }
}

img.nomargin {
  margin: 0;
}

p.white {
  color: white;
}
